import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../pages/auth/services/auth-slice";
import { authApi } from "../pages/auth/services/auth-api";
import { dashboardApi } from "../pages/dashboard/services/dashboard-api";
import { logementApi } from "../pages/logements/services/logement-api";
import { productApi } from "../pages/logements/services/product-api";
import { instructionApi } from "../pages/logements/services/instruction-api";
import logementSlice from "../pages/logements/services/logement-slice";
import {equipeEntretienApi} from "../pages/equipes/services/equipe-entretien-api";
import {prestationApi} from "../pages/planning/services/prestations-api";
import {dashboardEntretienApi} from "../pages/dashboard/services/dashboard-entretien";
import {clientApi} from "../pages/clients/services/client-api";
import {clientStatsApi} from "../pages/clients/services/user-stats-api";
import {planningApi} from "../pages/planning/services/planning-api";
import {planningSlice} from "../pages/planning/services/planning-slice";
import {logApi} from "../pages/planning/services/log-api";
import {paiementApi} from "../pages/planning/services/paiement-api";
import {paiementclientApi} from "../pages/clients/services/paiement-client";
import {factureclientApi} from "../pages/clients/services/facture-api";
import {suscriberPlanApi} from "../pages/equipes/services/suscribe-plan-api";
import {infoUserApi} from "../pages/equipes/services/indo-user-api";
import {statusColorApi} from "../pages/logements/services/logement-color-statut-api";
import { tarifApi } from "../pages/profil/tarifs/services/tarifs-api";
import {mediaprestationApi} from "../pages/logistique/services/mediaprestation-api";
import {controleFinPrestaApi} from "../pages/planning/services/control-qualite-api";


export default configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [logementApi.reducerPath]: logementApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [instructionApi.reducerPath]: instructionApi.reducer,
    [equipeEntretienApi.reducerPath]: equipeEntretienApi.reducer,
    [prestationApi.reducerPath]: prestationApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [clientStatsApi.reducerPath]: clientStatsApi.reducer,
    [dashboardEntretienApi.reducerPath]: dashboardEntretienApi.reducer,
    [planningApi.reducerPath]: planningApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [paiementApi.reducerPath]: paiementApi.reducer,
    [paiementclientApi.reducerPath]: paiementclientApi.reducer,
    [factureclientApi.reducerPath]: factureclientApi.reducer,
    [suscriberPlanApi.reducerPath]: suscriberPlanApi.reducer,
    [infoUserApi.reducerPath]: infoUserApi.reducer,
    [statusColorApi.reducerPath]: statusColorApi.reducer,
    [tarifApi.reducerPath]: tarifApi.reducer,
    [mediaprestationApi.reducerPath]: mediaprestationApi.reducer,
    [controleFinPrestaApi.reducerPath]: controleFinPrestaApi.reducer,
    planning: planningSlice,
    auth: authSlice,
    logement: logementSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      dashboardApi.middleware,
      logementApi.middleware,
      productApi.middleware,
      instructionApi.middleware,
      prestationApi.middleware,
      dashboardEntretienApi.middleware,
      clientApi.middleware,
      clientStatsApi.middleware,
      planningApi.middleware,
      logApi.middleware,
      paiementApi.middleware,
      paiementclientApi.middleware,
      factureclientApi.middleware,
      suscriberPlanApi.middleware,
      statusColorApi.middleware,
      infoUserApi.middleware,
      equipeEntretienApi.middleware,
      infoUserApi.middleware,
      equipeEntretienApi.middleware,
      mediaprestationApi.middleware,
      controleFinPrestaApi.middleware,
      tarifApi.middleware
    ),
});
