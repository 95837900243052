import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";
import { Layout, Row, Col } from 'antd';
import AuthVerify from "./utils/AuthVerify";
import AppHeader from './components/header';
import Sidebar from './components/sidebar';
import LoginPage from './pages/auth/login';
import DashBoardPage from './pages/dashboard';
import LogementPage from './pages/logements/index';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { logout } from "./pages/auth/services/auth-slice";
import EquipePage from './pages/equipes';
import PlanningPage from './pages/planning';
import LogistiquePage from './pages/logistique';
import HommeToutFairePage from './pages/homme-a-tout-faire';
import RapportPage from './pages/rapport';
import ParrainagePage from './pages/parrainage';
import ConciergeriePage from './pages/conciergerie';
import RegisterPage from './pages/auth/register';
import RegisterProfilPage from './pages/auth/register/register-profil-page';
import RegisterSuccessPage from './pages/auth/register/register-success-page';
import SendResetPasswordPage from "./pages/auth/reset-password/send-reset-password";
import ProfilPage from "./pages/profil";
import AppNotification from "./components/notification";
import AddTiercePage from "./pages/equipes/add-tierce";
import ResetPasswordPage from "./pages/auth/reset-password/reset-password";
import ClientPage from "./pages/clients";
import * as Sentry from "@sentry/react";
import { debugIntegration } from "@sentry/react";
import PmeMobilePage from "./pages/auth/register/pme-mobile-page";
const { Content } = Layout;

Sentry.init({
  dsn: "http://3e3ea4e7cef79a377a5a4a26d4e9d9fd@164.132.249.28:9050/3",
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  environment: "dev",
  debug: true,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const stripePromise = loadStripe("pk_test_51LxVm9HS31N8KDsIwOHxvm4rchcd7EavDgL6RtbwqpmsJJWGYXUCJ3wdzgYo9CHZ2hmuZqQ463oBH2wDQtkizSxA00Buq2UEu6");
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/register-profil" element={<RegisterProfilPage />} />
          <Route exact path="/page-de-confirmation" element={<RegisterSuccessPage />} />
          <Route exact path="/send-reset-password" element={<SendResetPasswordPage />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage />} />
          <Route exact path="/register-stripe-token/:user" element={<PmeMobilePage />} />
          <Route exact element={<PrivateWrapper auth={auth.token} />}>
            <Route element={<DashboardWrapper />}>
              <Route exact path="/dashboard" element={<DashBoardPage />} />
            </Route>

            <Route element={<LogementWrapper />}>
              <Route exact path="/clients" element={<ClientPage />} />
            </Route>

            <Route element={<LogementWrapper />}>
              <Route exact path="/logements" element={<LogementPage />} />
            </Route>

            <Route element={<EquipeWrapper />}>
              <Route exact path="/equipes-entretien" element={<EquipePage />} />
            </Route>

            <Route element={<PlanningWrapper />}>
              <Route exact path="/planning-menage" element={<PlanningPage />} />
            </Route>

            <Route element={<LogistiqueWrapper />}>
              <Route exact path="/qualite-logistique" element={<LogistiquePage />} />
            </Route>

            <Route element={<HommeAToutFaireWrapper />}>
              <Route exact path="/homme-a-tout-faire" element={<HommeToutFairePage />} />
            </Route>

            <Route element={<RapportWrapper />}>
              <Route exact path="/rapports" element={<RapportPage />} />
            </Route>

            <Route element={<ParrainageWrapper />}>
              <Route exact path="/parrainage" element={<ParrainagePage />} />
            </Route>

            <Route element={<ConciergerieWrapper />}>
              <Route exact path="/conciergerie-en-reseau" element={<ConciergeriePage />} />
            </Route>

            <Route element={<ProfilWrapper />}>
              <Route exact path="/mon-profil" element={<ProfilPage />} />
            </Route>
          </Route>
          <Route exact element={<PrivateGrandModalWrapper auth={auth.token} />}>
            <Route element={<AddTierceWrapper />}>
              <Route exact path="/inviter-un-tierce" element={<AddTiercePage />} />
            </Route>
          </Route>
        </Routes>
      </Elements>
      <AuthVerify logOut={handleLogout} />
    </BrowserRouter >
  );
}

const PrivateWrapper = ({ auth }) => {
  return auth ? (
    <>
      <Layout style={{ height: '100vh' }}>
        <Sidebar />
        <Layout >
          <AppHeader />
          <Content style={{ margin: '0 16px' }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <AppNotification />
    </>

  ) : (<Navigate to="/" />)
};
const PrivateGrandModalWrapper = ({ auth }) => {
  return auth ? (
    <>
      <Layout style={{ height: '100vh' }}>
        <Sidebar />
        <Layout >
          <Content style={{ margin: '0 16px' }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <AppNotification />
    </>

  ) : (<Navigate to="/" />)
};

const DashboardWrapper = () => {
  return (
    <div className="dashboard-page" data-aos="fade-in">
      <Row>
        <Col lg={5} />
        <Col lg={15}>
          <Outlet />
        </Col>
        <Col lg={4} />
      </Row>
    </div>
  );
};
const AddTierceWrapper = () => {
  return (
    <div className="addtierce-page" data-aos="fade-in">
      <Row>
        <Col lg={24}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

const LogementWrapper = () => {
  return (
    <div className="logement-page" data-aos="fade-in">
      <Outlet />
    </div>
  );
};

const PlanningWrapper = () => {
  return (
    <div className="planning-page" data-aos="fade-in">
      <Row>
        <Col lg={1} />
        <Col lg={22}>
          <Outlet />
        </Col>
        <Col lg={1} />
      </Row>
    </div>
  );
};

const EquipeWrapper = () => {
  return (
    <div className="equipe-page" data-aos="fade-in">
      <Row>
        <Col lg={3} />
        <Col lg={17}>
          <Outlet />
        </Col>
        <Col lg={4} />
      </Row>
    </div>
  );
};

const LogistiqueWrapper = () => {
  return (
    <div className="logistique-page" data-aos="fade-in">
      <Row>
        <Col lg={3} />
        <Col lg={17}>
          <Outlet />
        </Col>
        <Col lg={4} />
      </Row>
    </div>
  );
};


const HommeAToutFaireWrapper = () => {
  return (
    <div className="homme-a-tout-faire-page" data-aos="fade-in">
      <Outlet />
    </div>
  );
};

const RapportWrapper = () => {
  return (
    <div className="logistique-page" data-aos="fade-in">
      <Outlet />
    </div>
  );
};


const ParrainageWrapper = () => {
  return (
    <div className="parrainage-page" data-aos="fade-in">
      <Row>
        <Col lg={3} />
        <Col lg={18}>
          <Outlet />
        </Col>
        <Col lg={3} />
      </Row>
    </div>
  );
};


const ConciergerieWrapper = () => {
  return (
    <div className="conciergerie-page" data-aos="fade-in">
      <Outlet />
    </div>
  );
};
const ProfilWrapper = () => {
  return (
    <div className="profil-page" data-aos="fade-in">
      <Row>
        <Col lg={3} />
        <Col lg={19}>
          <Outlet />
        </Col>
        <Col lg={2} />
      </Row>
    </div>
  );
};


export default App;
